const INFO = {
	main: {
		title: "Ian Kibandi",
		name: "Ian Kibandi",
		email: "ian.kibandi@devcanary.com",
		logo: "../prof_photo.png",
	},

	socials: {
		twitter: "https://twitter.com/@kibandiian",
		github: "https://github.com/oryankibandi",
		linkedin: "https://linkedin.com/in/ian-kibandi",
		instagram: "https://instagram.com/",
		stackoverflow: "https://stackoverflow.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Backend Software Engineer and Software Architect.",
		description:
			"I am a backend software engineer with expertise in Node.js, Typescript and PostgreSQL. I design and build scalable, robust and maintainable backend systems. I enjoy bringing ideas to life using technology and creating value for clients' businesses by saving time, money and increasing their revenue.",
	},

	about: {
		title: "I’m Ian Kibandi. I live in Nairobi.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. I have mostly built and deployed backend systems which range from small applications to microservices architectures. I am interested in designing distributed systems and studying consensus algorithms. If you have a project in mind please feel free to reach out.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Tusenti",
			description:
				"A mobile wallet that makes your transaction charges work for you.",
			logo: "/images/tusenti.png",
			linkText: "View Project",
			link: "https://tusenti.co.ke",
		},

		{
			title: "Gatherease",
			description:
				"An event management platform that helps event planners manage their attendees and find catering services.",
			logo: "/images/gatherease.png",
			linkText: "View Project",
			link: "https://github.com/oryankibandi/Gatherease-backend",
		},

		{
			title: "Mobidoc",
			description:
				"Mobidoc is a tele-health platform that connects doctors to patients to offer a more flexible consultation experience",
			logo: "/images/mobidoc.png",
			linkText: "View Project",
			link: "https://github.com/oryankibandi/Mobidoc/tree/main",
		},
	],
};

export default INFO;
